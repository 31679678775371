<template>
  <section class="content px-4 py-2 my-2" name="MassiveOrders">
    <v-layout wrap>
      <v-flex xs12>
        <AppTitle title="Crear envio masivo" subtitle="Filtre por dia, mes y palabras para enviar cupones masivos" />
      </v-flex>
      <template v-if="!loading">
        <v-flex xs12 sm6>
          <v-layout wrap>
            <v-flex xs12 class="py-0">
              <p class="font-weight-bold mb-2">Día de inicio</p>
            </v-flex>
            <v-flex xs6 class="py-0">
              <v-select
                v-model="dayStartSelected"
                item-value="value"
                :items="days"
                label="Dia"
                item-text="name"
                solo
              ></v-select>
            </v-flex>
            <v-flex xs6 class="py-0">
              <v-select
                v-model="monthStartSelected"
                item-value="id"
                :items="months"
                label="Mes"
                item-text="name"
                solo
              ></v-select>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 sm6>
          <v-layout wrap>
            <v-flex xs12 class="py-0">
              <p class="font-weight-bold mb-2">Día de termino</p>
            </v-flex>
            <v-flex xs6 class="py-0">
              <v-select
                v-model="dayEndSelected"
                item-value="value"
                :items="days"
                label="Dia"
                item-text="name"
                solo
              ></v-select>
            </v-flex>
            <v-flex xs6 class="py-0">
              <v-select
                v-model="monthEndSelected"
                item-value="id"
                :items="months"
                label="Mes"
                item-text="name"
                solo
              ></v-select>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs6 sm2>
          <v-text-field
            v-model="dayBackSelected"
            type="text"
            label=""
            placeholder=""
            hint="¿Cuántos días hacia atrás desea verificar si se envió un cupón?"
            persistent-hint
            solo
          ></v-text-field>
        </v-flex>
        <v-flex xs6 sm3>
          <v-select
            v-model="wordsSelected"
            :items="wordsList"
            label="Listado de palabras"
            item-text="name"
            return-object
            hide-detail
            solo
          ></v-select>
        </v-flex>
        <v-flex xs12 sm3>
          <v-textarea
            v-model="words"
            solo
            placeholder="Palabras a filtrar"
            hint="Seperar con comas las palabras a filtrar"
            hide-detail
          ></v-textarea>
        </v-flex>
        <v-flex xs12 sm4>
          <v-checkbox
            v-model="verifyBuySend"
            class="mt-0"
            :label="`Verificar si se hizo una compra`"
            color="primary"
            solo
          />
          <v-layout v-if="verifyBuySend" wrap>
            <v-flex xs12 class="py-0">
              <p class="font-weight-bold mb-2">Rango de fechas</p>
            </v-flex>
            <v-flex xs6 class="py-0">
              <v-text-field
                v-model="verifyDateStart"
                :value="verifyDateStart"
                type="date"
                hint="Fecha de inicio"
                persistent-hint
                solo
              ></v-text-field>
            </v-flex>
            <v-flex xs6 class="py-0">
              <v-text-field
                v-model="verifyDateEnd"
                type="date"
                hint="Fecha de termino"
                persistent-hint
                solo
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 class="text-xs-right">
          <v-btn color="info" large class="ma-0" @click="getOrders">Filtrar</v-btn>
        </v-flex>
      </template>
      <v-flex xs12>
        <v-card>
          <AppCardTitle :title="`${orders.length} por enviar`">
            <template slot="button">
              <v-btn :disabled="orders.length === 0" small depressed color="success" class="ma-0" @click="openDialog">
                Enviar descuentos
              </v-btn>
            </template>
          </AppCardTitle>
          <v-divider></v-divider>
          <v-data-table
            v-model="selected"
            item-key="id"
            :headers="headers"
            :items="orders"
            :loading="loading"
            rows-per-page-text="Pedidos por pagina"
            no-data-text="No hay pedidos para generar boleta/factura"
            class="elevation-0"
            hide-actions
          >
            <template slot="items" slot-scope="props">
              <tr :active="props.selected">
                <td>
                  {{ props.item.id }}
                </td>
                <td>
                  {{ props.item.dateShipping }}
                </td>
                <td>
                  {{ props.item.message }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="dialogSend" width="500" persistent>
      <v-card>
        <AppTitleDialog
          :title="'Personalizar envío'"
          :close-action="
            () => {
              dialogSend = false
            }
          "
          close
        />
        <v-card-text class="pa-0">
          <v-container grid-list-lg>
            <v-text-field v-model="minSend" placeholder="" label="Minimo de compra"></v-text-field>
            <v-select
              v-model="typeDiscountSend"
              item-value="id"
              item-text="name"
              :items="itemsDiscountSend"
              label="Tipo de descuento"
            ></v-select>
            <v-text-field
              v-model="discountSend"
              placeholder="Feliz navidad..."
              label="Valor del descuento"
            ></v-text-field>
            <v-text-field v-model="expireSend" placeholder="" label="Dias de expiracion" hide-details></v-text-field>
            <v-layout wrap>
              <v-flex xs6>
                <v-checkbox v-model="typeSend" hide-details :value="1" color="primary" label="Envio de emai">
                </v-checkbox>
              </v-flex>
              <v-flex xs6>
                <v-checkbox
                  v-model="typeSend"
                  hide-details
                  :value="2"
                  color="primary"
                  class="mb-2"
                  label="Envio de wsp"
                ></v-checkbox>
              </v-flex>
              <v-flex v-if="typeSend.includes(1)" xs12>
                <v-text-field
                  v-model="subjectSend"
                  placeholder="Feliz navidad..."
                  label="Asunto del email"
                ></v-text-field>
                <v-select
                  v-model="emailSend"
                  item-value="url"
                  :items="emailLists"
                  label="Plantilla del email"
                  item-text="name"
                ></v-select>
                <template v-if="emailSend">
                  <iframe
                    style="width: 100%;height: 500px;"
                    :src="`https://api.eflores.cl/template-emails/${emailSend}.html`"
                    frameborder="0"
                  ></iframe>
                </template>
              </v-flex>
              <v-flex v-if="typeSend.includes(2)" xs12>
                <v-select
                  v-model="wspSend"
                  item-value="url"
                  :items="wspLists"
                  label="Plantilla del wsp"
                  item-text="name"
                ></v-select>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn flat color="back" depressed @click="dialogSend = false"> Volver </v-btn>
          <v-spacer></v-spacer>
          <v-btn flat color="accent" depressed @click="createEnvioMasivo"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { ENVIOS_MASIVOS, MASSIVE_ORDER, WSP_MSJ } from '../../../config'
import AppTitle from '../../useful/title.vue'
import AppCardTitle from '../../useful/titleCard.vue'
import AppTitleDialog from '../../useful/titleDialog.vue'

function getMonthName(monthNumber) {
  const date = new Date()
  date.setMonth(monthNumber - 1)

  return date.toLocaleString('es-Es', { month: 'long' })
}

export default {
  components: {
    AppTitle,
    AppCardTitle,
    AppTitleDialog
  },
  data() {
    const days = []
    for (let index = 1; index < 32; index++) {
      if (index < 10) {
        index = `0${index}`
      }
      days.push(String(index))
    }
    const months = []
    for (let index = 1; index < 13; index++) {
      if (index < 10) {
        index = `0${index}`
      }
      months.push({ id: index, name: getMonthName(index) })
    }

    const currentDate = new Date()
    let verifyDateStart = new Date(currentDate.getTime() - 5 * 24 * 60 * 60 * 1000)
    let verifyDateEnd = new Date(currentDate.getTime() + 5 * 24 * 60 * 60 * 1000)

    const year = currentDate.getFullYear()
    const month = ('0' + (verifyDateStart.getMonth() + 1)).slice(-2) // Add leading zero for single-digit months
    const day = ('0' + verifyDateStart.getDate()).slice(-2) // Add leading zero for single-digit days

    const month2 = ('0' + (verifyDateEnd.getMonth() + 1)).slice(-2) // Add leading zero for single-digit months
    const day2 = ('0' + verifyDateEnd.getDate()).slice(-2) // Add leading zero for single-digit days

    verifyDateStart = year + '-' + month + '-' + day
    verifyDateEnd = year + '-' + month2 + '-' + day2

    const dayStartSelected = ('0' + currentDate.getDate()).slice(-2)
    const monthStartSelected = ('0' + (currentDate.getMonth() + 1)).slice(-2)

    const dayEndSelected = ('0' + currentDate.getDate()).slice(-2)
    const monthEndSelected = ('0' + (currentDate.getMonth() + 1)).slice(-2)

    return {
      wordsSelected: null,
      dayStartSelected,
      monthStartSelected,
      dayEndSelected,
      monthEndSelected,
      dayBackSelected: 14,
      verifyBuySend: true,
      verifyDateStart,
      verifyDateEnd,
      dialogSend: false,
      days,
      months,
      minSend: 18000,
      typeDiscountSend: 0,
      discountSend: 15,
      expireSend: 14,
      subjectSend: null,
      emailSend: null,
      wspSend: null,
      typeSend: [],
      modalDate: false,
      loading: false,
      dialogEmail: false,
      date: this.$moment()
        .add(7, 'days')
        .format('YYYY-MM-DD'),

      subject: null,
      wordsList: [],
      emailLists: [
        // { id: 0, name: 'Por defecto', url: 'fecha_importante_otro' },
        // { id: 1, name: 'Cumpleaños', url: 'fecha_importante_cumpleano' },
        // { id: 2, name: 'Aniversarios', url: 'fecha_importante_aniversario' },
        // { id: 3, name: 'Especial Navidad', url: 'fecha_importante_navidad' },
        // { id: 4, name: '14 de Febrero', url: 'dia_de_los_enamorados' },
        { id: 4, name: 'Día de la madre', url: 'fecha_importante_dia_de_la_madre' }
      ],
      wspLists: [
        // { id: 0, name: 'Por defecto', url: 'fecha_importante_otro' },
        // { id: 1, name: 'Cumpleaños', url: 'fecha_importante_cumpleano' },
        // { id: 2, name: 'Aniversarios', url: 'fecha_importante_aniversario' },
        // { id: 3, name: 'Especial Navidad', url: 'fecha_importante_navidad' },
        // { id: 4, name: '14 de Febrero', url: 'dia_de_los_enamorados' },
        { id: 4, name: 'Día de la madre', url: 'f_importante_dia_de_la_madre' }
      ],
      itemsDiscountSend: [{ id: 0, name: 'Porcentaje' }, { id: 1, name: 'Monto' }],
      words: null,
      orders: [],
      ordersAll: [],
      selected: [],
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      headers: [
        {
          text: 'Pedido',
          align: 'left',
          sortable: false,
          value: 'order'
        },
        {
          text: 'Fecha de entrega',
          align: 'left',
          sortable: false,
          value: 'date_shipping'
        },
        {
          text: 'Mensaje',
          align: 'left',
          sortable: false,
          value: 'message'
        }
      ]
    }
  },
  computed: {
    massive() {
      return this.$store.getters.getMassive
    },
    year() {
      const nd = new Date()
      return nd.getFullYear()
    },
    dateStartSelected() {
      const { dayStartSelected, monthStartSelected, year } = this
      if (dayStartSelected && monthStartSelected) return `${year}-${monthStartSelected}-${dayStartSelected}`
      return null
    },
    dateEndSelected() {
      const { dayEndSelected, monthEndSelected, year } = this
      if (dayEndSelected && monthEndSelected) return `${year}-${monthEndSelected}-${dayEndSelected}`
      return null
    }
  },
  watch: {
    wordsSelected(e) {
      if (e) {
        this.words = e.words
        this.filterOrders()
      }
    }
  },
  mounted() {
    this.getMsjWsp()
  },
  methods: {
    async getMsjWsp() {
      try {
        const res = await this.$http.get(WSP_MSJ)
        this.wordsList = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente 1'
        })
      }
    },
    async getOrders() {
      const {
        dateStartSelected,
        dateEndSelected,
        dayBackSelected,
        verifyBuySend,
        verifyDateStart,
        verifyDateEnd
      } = this
      if (dateStartSelected && dateEndSelected) {
        try {
          const res = await this.$http.get(`${MASSIVE_ORDER}`, {
            params: {
              dateStart: dateStartSelected,
              dateEnd: dateEndSelected,
              dayBack: dayBackSelected,
              verify: verifyBuySend,
              verifyDateStart,
              verifyDateEnd
            }
          })
          this.orders = res.data
          this.ordersAll = res.data
          this.filterOrders()
        } catch (error) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Ocurrio un error, intente nuevamente'
          })
        }
      }
    },
    async createEnvioMasivo() {
      const {
        minSend,
        typeDiscountSend,
        discountSend,
        expireSend,
        subjectSend,
        emailSend,
        orders,
        typeSend,
        wspSend,
        dateStartSelected,
        dateEndSelected,
        dayBackSelected,
        verifyBuySend,
        verifyDateStart,
        verifyDateEnd
      } = this
      const orders_ = []
      orders.forEach(e => {
        orders_.push(e.id)
      })
      try {
        if (!discountSend) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Todos los campos son obligatorios'
          })
          return false
        }
        if (!expireSend) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Todos los campos son obligatorios'
          })
          return false
        }
        if (!minSend) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Todos los campos son obligatorios'
          })
          return false
        }
        if (!subjectSend && typeSend.includes(1)) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Todos los campos son obligatorios'
          })
          return false
        }
        if (!emailSend && typeSend.includes(1)) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Todos los campos son obligatorios'
          })
          return false
        }
        if (!wspSend && typeSend.includes(2)) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Todos los campos son obligatorios'
          })
          return false
        }
        if (typeSend.length === 0) {
          this.$store.dispatch('changeSnack', {
            active: true,
            text: 'Todos los campos son obligatorios'
          })
          return false
        }
        const res = await this.$http.post(ENVIOS_MASIVOS, {
          minSend,
          typeDiscountSend,
          discountSend,
          expireSend,
          subjectSend,
          emailSend,
          orders: orders_,
          typeSend,
          wspSend,
          dateStartSelected,
          dateEndSelected,
          dayBackSelected,
          verifyBuySend,
          verifyDateStart,
          verifyDateEnd
        })
        this.$router.push({
          name: 'settingsEnvioMasivoId',
          params: { id: res.data.id }
        })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    sendOrdersAction() {
      if (this.emailSend) {
        this.$store.dispatch('setConfirm', {
          active: true,
          title: '¿Esta seguro enviar mail masivo?',
          description: '',
          action: this.sendOrders
        })
      } else {
        this.$store.dispatch('changeSnack', { active: true, text: 'Seleccione un email' })
      }
    },
    sendOrders() {
      this.$store.dispatch('setConfirm', { active: false })
      this.loading = true
      this.dialogSend = false
      this._.forEach(this.orders, (item, index) => {
        this.orders[index].send = 1
      })
      this.sendFirst()
    },
    sendFirst() {
      const key = this._.findKey(this.orders, { send: 1 })
      this.$store.dispatch('changeMassive', {
        key,
        send: 1
      })
    },
    filterOrders() {
      if (!this.words) {
        return
      }
      const words = this.words.split(',')
      this.orders = []
      if (words !== '') {
        this._.forEach(this.ordersAll, item => {
          this._.forEach(words, word => {
            if (this._.includes(item.message, word.trim())) {
              this.orders.push(item)
              return false
            }
          })
        })
      }
    },
    openDialog() {
      this.dialogSend = true
    }
  }
}
</script>
