import { render, staticRenderFns } from "./nuevo.vue?vue&type=template&id=59fe2994&"
import script from "./nuevo.vue?vue&type=script&lang=js&"
export * from "./nuevo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCheckbox } from 'vuetify/lib'
import { VContainer } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
import { VTextarea } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCheckbox,VContainer,VDataTable,VDialog,VDivider,VFlex,VLayout,VSelect,VSpacer,VTextField,VTextarea})
